import uajs from './module/ua';

(function ($) {
	const ua = uajs();

	$(document).ready(function () {

		// アンカーリンク
		$('a[href*="#"]').click(function (e) {
			var href = $(this).attr('href');
			if (href.match(/^#/) && $(href).length > 0) {
				e.preventDefault();
				$('html, body').animate({
					scrollTop: $(href).offset().top
				}, 'fast');
				return false;
			}
		});

		// Gnav ハンバーガーメニューclass追加、スクリーンリーダーでbuttonの開閉を認識
		$('.l-nav__btn.js-gnav').click(function (e) {
			if (window.innerWidth <= 767) {
				e.preventDefault();
				$("body").toggleClass("l-nav__contents-open");
				if ($(this).attr('aria-expanded') == 'true') {
					$(this).attr('aria-expanded', 'false');
					$(".l-nav__body").attr('aria-hidden', 'true');
				} else {
					$(this).attr('aria-expanded', 'true');
					$(".l-nav__body").attr('aria-hidden', 'false');
				}

				$(".l-nav-01__list, .l-nav-02").removeClass("js-nav-close");
				$(".l-nav-01__sub-wrap").removeClass("l-nav-01-sub-open");
			}
		});

		$(window).on('load resize', function () {
			if (window.innerWidth <= 767) {
				$(".l-nav__body").attr('aria-hidden', 'true');
			} else {
				$(".l-nav__body").attr('aria-hidden', 'false');
			}
		});

		// Gnav l-nav-02 PC class追加
		$('.l-nav-02__btn.js-nav-02').on('click', function (e) {
			if (window.innerWidth >= 768) {
				e.preventDefault();
				$(".l-nav-02").toggleClass("l-nav-02-open");
			}
		});

		// Gnav l-nav-01-sub SP class追加
		$(".link-01-sp.js-nav-sub").on('click', function () {
			var targetClassName = '.' + $(this).data('target_class');
			$(targetClassName).toggleClass("l-nav-01-sub-open");
			$(".l-nav-01__list, .l-nav-02").addClass("js-nav-close");
		});

		$(".l-nav-01__sub-ttl.js-nav-sub-ttl").on('click', function () {
			$(".l-nav-01__list, .l-nav-02").removeClass("js-nav-close");
			$(".l-nav-01__sub-wrap").removeClass("l-nav-01-sub-open");
		});

		// header search PC
		$('.js-search-open').on('click', function () {
			if (window.innerWidth >= 768) {
				$(".js-search-open").toggleClass("search-open");
				$(".search-box.search-g").slideToggle();
				if ($(this).attr('aria-expanded') == 'true') {
					$(this).attr('aria-expanded', 'false');
					$(".search-box.search-g").attr('aria-hidden', 'true');
				} else {
					$(this).attr('aria-expanded', 'true');
					$(".search-box.search-g").attr('aria-hidden', 'false');
				}
			}
		});

		$(window).on('load resize', function () {
			if (window.innerWidth >= 768) {
				$(".search-box.search-g").attr('aria-hidden', 'true');
			} else {
				$(".search-box.search-g").attr('aria-hidden', 'false');
			}
		});

		// slick TOP hero
		$('.js-top-slider').slick({
			autoplay: true,
			autoplaySpeed: 3500,
			arrows: true,
			dots: false,
			fade: true,
			pauseOnHover: false,
			slidesToShow: 1,
			speed: 2000
		});

		// tab クリックでclass追加
		$('.tab').on('click', function () {
			$('.js-active').removeClass('js-active');
			$(this).addClass('js-active');
			$('.js-show').removeClass('js-show');
			const index = $(this).index();
			$('.panel').eq(index).addClass('js-show');
		});

		// ofi
		objectFitImages();

	});

})(jQuery);
